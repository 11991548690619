// import fonts files
@import "./assets/fonts/inter/interfont.scss";
@import "./assets/fonts/noto-kufi-arabic/notokufi.scss";
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/css-star-rating/scss/star-rating";

:root {
  --primary-color: #c0c0c0;
  --light-color: #f4f6f9;
  --red-color: #ee3a3a;

  $form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2.5' fill='#fff'/></svg>") !default;
}

* {
  outline: none !important;
}

html {
  body {
    font-family: "Inter", "Noto Kufi Arabic";
    margin: 0px !important;
  }

  &[dir="rtl"] {
    .toastContainer {
      right: 0px;
      left: unset;
    }

    app-modal {
      left: unset;
      right: 0px;

      .modal {
        left: unset;
        right: 0px;

        &-content {
          @media (min-width: 992px) {
            left: unset;
            right: 0px;
          }
        }
      }
    }
  }
}

body {
  // scroll bar style
  ::-webkit-scrollbar {
    @media not (pointer: coarse) {
      height: 5px;
      width: 10px;
    }
  }
  ::-webkit-scrollbar-track {
    @media not (pointer: coarse) {
      background: #f5f5f5;
    }
  }
  ::-webkit-scrollbar-thumb {
    @media not (pointer: coarse) {
      background: #cbd7e2;
      border-radius: 2px;
    }
  }
  ::-webkit-scrollbar-thumb:hover {
    @media not (pointer: coarse) {
      background: #a5b3c0;
    }
  }
}

h1 {
  font-size: 2.2rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.8rem;
}
h4 {
  font-size: 1.5rem;
}
h5 {
  font-size: 1.2rem;
}
h6 {
  font-size: 1rem;
}
p,
span,
button {
  font-size: 0.9rem;
}

.status {
  padding: 5px 15px;
  border-radius: 8px;
  background-color: #5e58731f;

  &.payment_completed,
  .accepted,
  .pickup_ready,
  .out_for_delivery,
  .delivered,
  .completed {
    background-color: #e5f8ed;
    color: #28c76f;
  }
  &.payment_cancelled,
  .rejected,
  .not_delivered,
  .cancelled {
    background-color: #ea54551f;
    color: #ea5455;
  }
  &.pending,
  .awaiting_payment {
    background-color: #ff9f431f;
    color: #ff9f43;
  }
  &.scheduled {
    background-color: #eeebff;
    color: #7c07bb;
  }
  &.being_prepared {
    background-color: #00cfe81f;
    color: #00cfe8;
  }
}

.offcanvas-backdrop {
  max-width: 100%;
  position: absolute !important;
}

google-map {
  .map-container {
    width: 100% !important;
    height: 100% !important;
  }
}

.gm-style {
  img {
    position: relative;

    &:before {
      content: "";
      display: block;
      position: absolute;
      border: 5px solid #000;
      width: 100px;
      height: 100px;
    }
  }
}

ng-otp-input {
  &.invalid {
    input {
      background-color: rgba(255, 82, 82, 0.25);
      border-color: rgba(255, 82, 82, 0.68);
    }
  }

  &.otpLoading {
    input {
      transition: all 0.2s linear;
      background-color: #f5f5f5;
    }
  }

  .ng-otp-input-wrapper {
    max-width: 400px;
    margin: auto;
    display: flex;
    justify-content: space-evenly;

    input {
      color: #323643;
      font-size: 20px !important;
      font-weight: 600;
      margin: 0px !important;
      width: 40px !important;
      height: 40px !important;

      @media (min-width: 576px) {
        width: 50px !important;
        height: 50px !important;
      }
    }
  }
}

.spinner-border {
  width: 1.5rem !important;
  height: 1.5rem !important;
  border-width: 3px !important;
  border-color: #fff;
  border-right-color: transparent;
}

.loadingSpinner {
  width: 30px;
  height: 30px;
}

.star {
  cursor: pointer;
  width: 25px !important;
  height: 25px !important;
}

.screenBtn {
  @media (max-width: 992px) {
    position: fixed;
    bottom: 20px;
    left: 1.5rem;
    width: calc(100% - 3rem);
  }

  @media (max-width: 576px) {
    left: 1rem;
    width: calc(100% - 2rem);
  }
}

.spliter {
  height: 5px;
  background: #f5f5f5;
  display: block;
  border: none;
  opacity: 1;
  width: calc(100% + 2rem);
  margin-inline-start: -1rem;

  @media (min-width: 576px) {
    width: calc(100% + 3rem);
    margin-inline-start: -1.5rem;
  }
}

app-modal {
  position: fixed;
  z-index: 1020;
  top: 0;
  left: 0;
}

.rating.disabled .star-container .star {
  opacity: 1 !important;
}
